.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
  display: none;
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  font-size: 1.25rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #3dccdc;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #3dccdc;
}
