@import "../node_modules/bulma/sass/utilities/initial-variables";
@import "../node_modules/bulma/sass/utilities/functions";
@import url('https://fonts.cdnfonts.com/css/waltograph');

                
/*
    here comes the custom styling
*/
$lightgray: #d3d3d3;
$silver: #c0c0c0;
$darkgray: #a9a9a9;
$gray: #808080;
$metalicSilver: #aaa9ad;

$Goldenyellow: #ffdf00;
$Metallicgold: #d4af37;
$Oldgold: #cfb53b;
$Vegasgold: #c5b358;
$Palegold: #e6be8a;
$Goldenbrown: #996515;

$metalicgold: #d4af37;

$snow: rgba(255, 250, 250, 0.9);

$primary: $metalicSilver;
$success: $lightgray;
$info: #3dccdc;

$dimensions: 16 24 32 48 64 96 128 256 512 1024 !default;

* {
  font-family: 'Waltograph', sans-serif;
  font-family: 'Waltograph UI', sans-serif;

}

body {
  background-color: $snow;
}

p,
ul li {
  font-size: 1.25rem;
}

.threed {
  transform: translate(10%, -10%);
  margin: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: $snow;
  text-shadow: 1px 1px 1px $silver, 1px 2px 1px $silver, 1px 3px 1px $silver,
    1px 4px 1px $silver, 1px 5px 1px $silver, 1px 6px 1px $silver,
    1px 7px 1px $silver, 1px 8px 1px $silver, 1px 9px 1px $silver,
    1px 10px 1px $silver, 1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}

a.navbar-item,
.navbar-link .is-active:hover {
  border-radius: 25%;
}

@import "../node_modules/bulma/bulma";
